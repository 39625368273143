import * as React from 'react';
import Container from '@mui/material/Container';
// Including AppFooter at Main level so footer doesn't flash at top of page for any new page load
import { Breakpoint } from '@mui/system';
import { AppFooter } from './AppFooter';

type MainProps = {
  maxWidth?: Breakpoint;
  showLegalFooter?: boolean;
  plating?: React.ReactNode;
  children?: React.ReactNode;
};

const Main = ({ maxWidth = 'lg', showLegalFooter = true, plating, children }: MainProps) => {
  return (
    <>
      {plating}
      <Container component="main" fixed maxWidth={maxWidth} sx={{ pt: 10, pb: 16 }}>
        {children}
      </Container>
      {/* 
      Including AppFooter within <Main /> so that the footer doesn't flash at top of page during each page load.
      Has a flag to disable it for internal-only pages where it isn't necessary.
      */}
      {showLegalFooter && <AppFooter maxWidth={maxWidth} />}
    </>
  );
};

export type { MainProps };
export { Main };
