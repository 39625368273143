import { AppRootGETContract } from '@local/trip-promotion-types';
import { axios } from '../../utils/axios';
import { createDataProvider } from '../../utils/dataProvider';

const getCustomerAppRootData = async (): Promise<AppRootGETContract> => {
  const uri = '/customer/app';
  const { data } = await axios.get<AppRootGETContract>(uri);

  return data;
};

const [useCustomerAppRootContext, CustomerAppRootProvider] = createDataProvider<AppRootGETContract>();

export { getCustomerAppRootData, useCustomerAppRootContext, CustomerAppRootProvider };
