import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme } from '@eftours/material-ui-gud';
import CssBaseline from '@mui/material/CssBaseline';
import { useCopyToClipboard } from '../../hooks/lib/useCopyToClipboard';
import { ClipboardProvider } from '../../hooks/clipboardContext';

type LayoutProps = {
  children: React.ReactNode;
  globalHeader?: React.ReactNode;
  content: Record<string, string>;
};

const DEFAULT_LOCALE = 'en-US';

const Layout = ({ children, globalHeader, content }: LayoutProps) => {
  const [setClipboard, clipboardNotifications] = useCopyToClipboard();

  return (
    // FIXME: pass in the locale based on the URL domain

    <IntlProvider messages={content} locale={DEFAULT_LOCALE} defaultLocale={DEFAULT_LOCALE}>
      <ThemeProvider theme={lightTheme}>
        <ClipboardProvider value={setClipboard}>
          {clipboardNotifications}
          <CssBaseline enableColorScheme />
          {globalHeader}
          {/* Child pages should use our <Main /> component to apply max-width and columns, which vary per page. */}
          {children}
        </ClipboardProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export { Layout };
