import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import type { Theme } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { lightTheme } from '@eftours/material-ui-gud';
import { ReactComponent as EtusLogoDesktop } from '../../assets/logos/etus-desktop.svg';
import { ReactComponent as EausLogoDesktop } from '../../assets/logos/eaus-desktop.svg';
import { ReactComponent as EtfcLogoDesktop } from '../../assets/logos/etfc-desktop.svg';
import { ReactComponent as EtcaLogoDesktop } from '../../assets/logos/etca-desktop.svg';
import { ReactComponent as EtusLogoMobile } from '../../assets/logos/etus-mobile.svg';
import { ReactComponent as EausLogoMobile } from '../../assets/logos/eaus-mobile.svg';
import { ReactComponent as EtfcLogoMobile } from '../../assets/logos/etfc-mobile.svg';
import { ReactComponent as EtcaLogoMobile } from '../../assets/logos/etca-mobile.svg';
import { useCustomerAppRootContext } from '../../routes/AppRoot/CustomerProvider';
import { getSiteCodeFromHostname, getLogoUrlFromSiteCode } from '../../utils/urls';

const siteCode = getSiteCodeFromHostname();

const getBrandLogoDesktop = () => {
  switch (siteCode) {
    case 'EAUS':
      return EausLogoDesktop;
    case 'ETFC':
      return EtfcLogoDesktop;
    case 'ETCA':
      return EtcaLogoDesktop;
    case 'ETUS':
    default:
      return EtusLogoDesktop;
  }
};

const getBrandLogoMobile = () => {
  switch (siteCode) {
    case 'EAUS':
      return EausLogoMobile;
    case 'ETFC':
      return EtfcLogoMobile;
    case 'ETCA':
      return EtcaLogoMobile;
    case 'ETUS':
    default:
      return EtusLogoMobile;
  }
};

const logoUrl = getLogoUrlFromSiteCode(siteCode);

const BrandLogoMobile = getBrandLogoMobile();
const BrandLogoDesktop = getBrandLogoDesktop();

const StyledBrandLogoDesktop = styled(BrandLogoDesktop)({
  fill: '#191919',
  height: '49px',
});

const StyledBrandLogoMobile = styled(BrandLogoMobile)({
  fill: '#191919',
  height: '32px',
});

/**
 * The user's global header menu/profile. This will differ significantly than the internal user's,
 * hence why we have separate components
 *
 * FIXME: Actually show the GL name here
 */
const CustomerProfile = () => {
  const { userFullName } = useCustomerAppRootContext().data || '';

  return userFullName ? (
    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
      <Avatar sx={{ width: 24, height: 24, mr: 2 }} imgProps={{ height: '100%' }}>
        <AccountCircleIcon sx={{ height: '100%', width: '100%' }} />
      </Avatar>
      <Typography>{userFullName}</Typography>
    </Box>
  ) : (
    <></>
  );
};

// FIXME: Logo disapears when wrapped in an anchor tag
const CustomerGlobalHeader = () => {
  const useDesktopLogo = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <ThemeProvider theme={lightTheme}>
      <Paper square sx={{ backgroundColor: 'common.white', color: 'common.black' }}>
        <Container fixed maxWidth="lg">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            sx={{ height: 96, py: 2 }}
          >
            <Link href={logoUrl} sx={{ lineHeight: 0, py: 1 }}>
              {useDesktopLogo ? <StyledBrandLogoDesktop /> : <StyledBrandLogoMobile />}
            </Link>
            <CustomerProfile />
          </Stack>
        </Container>
      </Paper>
    </ThemeProvider>
  );
};

export { CustomerGlobalHeader };
