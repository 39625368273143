import { createBrowserRouter, Navigate, RouterProvider as BaseRouteProvider } from 'react-router-dom';
import { lazyWithRetry } from './lazyImport';
import { Page } from './Page';

const CustomerAppRoot = lazyWithRetry(() => import('./routes/AppRoot/Customer'));
const AdminAppRoot = lazyWithRetry(() => import('./routes/AppRoot/Admin'));
const PublicAppRoot = lazyWithRetry(() => import('./routes/AppRoot/Public'));
const Dashboard = lazyWithRetry(() => import('./routes/Dashboard'));
const AdminTemplateEditor = lazyWithRetry(() => import('./routes/AdminTemplateEditor'));
const AdminTripRoot = lazyWithRetry(() => import('./routes/TripRoot/Admin'));
const CustomerTripRoot = lazyWithRetry(() => import('./routes/TripRoot/Customer'));
const PublicTripRoot = lazyWithRetry(() => import('./routes/TripRoot/Public'));
const AdminPlan = lazyWithRetry(() => import('./routes/AdminPlan'));
const AdminEventRoot = lazyWithRetry(() => import('./routes/AdminEvent'));
const ErrorPage = lazyWithRetry(() => import('./components/Error/NotFound'));
const AdminCreatePlan = lazyWithRetry(() => import('./routes/AdminCreatePlan'));
const ReadonlyEvent = lazyWithRetry(() => import('./routes/Event'));
const CustomerPlan = lazyWithRetry(() => import('./routes/CustomerPlan'));
const GuestPlan = lazyWithRetry(() => import('./routes/GuestPlan'));

// FIXME: Lazy load these routes, ideally with the lazyWithRetry from TREX
const router = createBrowserRouter([
  {
    path: '/not-found',
    element: (
      <Page name="NotFoundPage">
        {/* // FIXME: Actual not found page */}
        <ErrorPage />
      </Page>
    ),
  },
  // Redirect to /not-found if no better path is available
  // FIXME: can we use Navigate.state prop to tell the not found page what the requested not found path was?
  {
    path: '*',
    element: <Navigate to="/not-found" replace={true} />,
  },
  {
    path: '/trip',
    element: (
      <Page name="CustomerAppRoot">
        <CustomerAppRoot />
      </Page>
    ),
    children: [
      {
        path: `:tripId`,
        element: <CustomerTripRoot />,
        children: [
          {
            path: `plan/:planId`,
            element: (
              <Page name="CustomerPromotionPlanCalendar">
                <CustomerPlan />
              </Page>
            ),
          },
          {
            path: `event/:eventId`,
            element: (
              <Page name="CustomerEventPreview">
                <ReadonlyEvent />
              </Page>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    element: (
      <Page name="AdminAppRoot">
        <AdminAppRoot />
      </Page>
    ),
    children: [
      {
        index: true,
        element: (
          <Page name="AdminDashboard">
            <Dashboard />
          </Page>
        ),
      },
      {
        path: 'templates',
        element: (
          <Page name="AdminMetaUtil">
            <AdminTemplateEditor />
          </Page>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="./plan" replace={true} />,
          },
        ],
      },
      {
        path: 'trip', // should not be here without /:tripId, so redirect to parent route
        element: <Navigate to="../" replace={true} />,
      },
      {
        path: `trip/:tripId`,
        element: (
          <Page name="AdminTripRoot">
            <AdminTripRoot />
          </Page>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="./plan" replace={true} />,
          },
          {
            path: `plan`,
            element: (
              <Page name="AdminCreatePlan">
                <AdminCreatePlan />
              </Page>
            ),
          },
          {
            path: `plan/:planId`,
            element: (
              <Page name="AdminManagePlan">
                <AdminPlan />
              </Page>
            ),
          },
          {
            path: 'event', // should not be here without /:eventId, so redirect to parent route
            element: <Navigate to="../" replace={true} />,
          },
          {
            path: `event/:eventId`,
            element: (
              <Page name="AdminEventRoot">
                <AdminEventRoot />
              </Page>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/public',
    element: (
      <Page name="PublicAppRoot">
        <PublicAppRoot />
      </Page>
    ),
    children: [
      {
        path: `trip/:tripId`,
        element: <PublicTripRoot />,
        children: [
          {
            path: `share`,
            children: [
              {
                path: `plan/:planId`,
                element: (
                  <Page name="PublicPromotionPlanCalendar">
                    <GuestPlan />
                  </Page>
                ),
              },
              {
                path: `event/:eventId`,
                element: (
                  <Page name="PublicEventPreview">
                    <ReadonlyEvent />
                  </Page>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
]);
const RouteProvider = () => <BaseRouteProvider router={router} />;

export { RouteProvider };
