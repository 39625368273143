import { createLogger, Environments } from '@eftours/web-logger-typescript';
import { getViewportSize, resolveUrlConfigTemplate } from '@local/cx-client-utils';
import { Metric } from 'web-vitals';
import { reportWebVitals, WebVitals } from './reportWebVitals';

/**
 * FIXME: `navigator.userAgentData isn't a fully supported property, but we conditionally use it in the logger below. This
 * would ideally be a type shipped directly from typescript, as a part of `Navigator`. We're just extending it here to be
 * explicit about expected types
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
 */
declare global {
  type NavigatorUAData = {
    brands: string[];
    mobile: boolean;
    platform: string;
  };
  interface Navigator {
    userAgentData?: NavigatorUAData;
  }
}

/**
 * logger is our application-level logger instance. The following env vars need to be
 * set.
 *
 * REACT_APP_LOGGER_SERVICE
 * REACT_APP_LOGGER_ENVIRONMENT
 * REACT_APP_LOGGER_ROLLBAR_ACCESS_TOKEN
 * REACT_APP_LOGGER_BEACON_ENDPOINT?
 */
const logger = createLogger({
  service: process.env.REACT_APP_LOGGER_SERVICE as string,
  environment: process.env.REACT_APP_LOGGER_ENVIRONMENT as Environments,
  rollbar: {
    accessToken: process.env.REACT_APP_LOGGER_ROLLBAR_ACCESS_TOKEN || '',
  },
  beacon: {
    endpoint: resolveUrlConfigTemplate(`${process.env.REACT_APP_BACKEND_URL}/telemetry`),
  },
});

/**
 * Do a few things when first creating the logger
 */
const initializeClientLoggerMeta = () => {
  // Set an initial page load correlation id;
  logger.setCorrelationId();

  logger.appendMeta({
    // Set a sessionId for all future events regardless of the current correlationId
    sessionId: logger.getCorrelationId(),
  });

  reportWebVitals(({ id, name, value, delta }: Metric) => {
    logger.info(WebVitals[name] || 'Unknown Web Vital name', {
      logType: 'WebVitals',
      event: name,
      eventId: id,
      eventValue: value,
      eventDelta: delta,
    });
  });
  // Log some device/user information as soon as the app renders.
  logger.info('AppInitialization', {
    // FIXME: We should be migrating to navigator.userAgentData, but not all browsers support it yet, so here we're doing both
    userAgent: navigator.userAgent,
    userAgentData: navigator.userAgentData || 'navigator.userAgentData not supported in this browser',

    // Does this device have multiple touch points? i.e., phone or tablet or fancy laptop.
    // Where this is undefined won't be a touch screen either.
    touchScreen: navigator.maxTouchPoints > 1,

    // FIXME: We should consider using window.visualViewport, but this is how our CSS is rendering
    // so it probably makes more sense.
    viewport: getViewportSize(),

    // We want to know what page referred the user to this page/app (backend api can't figure this out)
    referrer: document.referrer,

    // We're curious to see what languages our users prefer based on browser setting.
    // Can have multiple preferred langs, but fall back to `navigator.language` if
    // `navigator.languages` isn't available.
    languages: navigator.languages || [navigator.language],
  });
};
// go ahead and run logger init meta while this module is resolved
initializeClientLoggerMeta();

export { logger };
