import { Dispatch, SetStateAction, useState } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';

type ToastProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  severity: AlertColor;
} & Pick<SnackbarProps, 'autoHideDuration'>;

/**
 * Wrapper around MUI snackbar and closeable alert in order to easily leverage notifications in the app
 *
 * Expects an externally defined react state/setState in order for it to know when to show/hide itself
 */
const Toast = ({ open, setOpen, children, severity, autoHideDuration = 6000 }: ToastProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={() => setOpen(false)}
    >
      <Alert variant="filled" severity={severity} sx={{ width: '100%' }} onClose={() => setOpen(false)}>
        {children}
      </Alert>
    </Snackbar>
  );
};

type UseToastProps = Omit<ToastProps, 'open' | 'setOpen' | 'children'> & {
  message: React.ReactNode;
};

/**
 * A wrapper around Toast to generate a toast with built-in state for you
 */
const useToast = ({ message, ...props }: UseToastProps): [Dispatch<SetStateAction<boolean>>, React.ReactNode] => {
  const [open, setOpen] = useState<boolean>(false);
  const toast = (
    <Toast open={open} setOpen={setOpen} {...props}>
      {message}
    </Toast>
  );

  return [setOpen, toast];
};

export { useToast };
