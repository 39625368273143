import {
  createDataProvider as createDataProviderBase,
  WrappedDataProviderPropsDefaults,
} from '@eftours/cx-react-utils';
import { AxiosError } from 'axios';
import { LoadingBar } from '../components/LoadingBar';

const defaultOptions = {
  renderLoading: () => <LoadingBar />,
};

/**
 * Wrap the base createDataProvider function to define AxiosError as the standard errorType
 * @param defaultProps
 * @returns
 */
const createDataProvider = <T, E = undefined>({
  renderLoading = defaultOptions.renderLoading,
  ...rest
}: WrappedDataProviderPropsDefaults<T, AxiosError<E>> = defaultOptions) => {
  return createDataProviderBase<T, AxiosError<E>>({ renderLoading, ...rest });
};

export { createDataProvider };
