import { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { logger } from '../utils/logger';

/**
 * LoadingBar produces a slim loading bar to be used at the top of the page or
 * within a main content area. It's not meant to be a long-term loading indicator,
 * but we needed something for PageProvider POC.
 */
const LoadingBar = () => {
  // Log the estimated duration the loading bar is show to customers.
  // This will run once on mount and once on unmount.
  useEffect(() => {
    const loadingStart = performance.now();
    return () => {
      // FIXME: some folks on the internet say we need to wait a tick if we
      // want to actually trigger this when the component has been unmounted vs
      // being sent to be unmounted.
      setTimeout(() => {
        const loadingEnd = performance.now();
        logger.info('Loading component unmounted', {
          logType: 'LoadingBarDuration',
          durationMs: Math.round(loadingEnd - loadingStart),
        });
      });
    };
  }, []);

  return <LinearProgress />;
};

export { LoadingBar };
