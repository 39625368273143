import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Settings } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ClickTrackingBoundary } from './components/ClickTrackingBoundary';
import { reportWebVitals } from './reportWebVitals';
import { RouteProvider } from './router';

// Forcing luxon to use utc. We're not dealing with zones for now.
// FIXME: ... right?.... RIGHT? ... RIGGGHHHT?
Settings.defaultZone = 'utc';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ClickTrackingBoundary>
        <RouteProvider />
      </ClickTrackingBoundary>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
