import { FormattedMessage } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { Main } from '../layout/Main';

/**
 * Just trying to create a basic error component
 */
const GenericErrorPage = ({
  header = <FormattedMessage id="GenericErrorPage.header" defaultMessage="Something went wrong" />,
  children = (
    <FormattedMessage id="GenericErrorPage.message" defaultMessage="An issue occurred when trying to load the page." />
  ),
  actions,
}: {
  header?: React.ReactNode;
  children?: React.ReactNode;
  actions?: React.ReactNode;
}) => {
  return (
    <div id="error-page">
      <Main showLegalFooter={false}>
        <Card>
          {header && <CardHeader variant="divider" title={header} />}
          {children && <CardContent>{children}</CardContent>}
          {actions && (
            <>
              <Divider variant="fullWidth" sx={{ marginTop: 0 }} />
              <CardActions
                sx={{
                  paddingX: '24px',
                  paddingY: '32px',
                }}
              >
                <Stack direction="row" width="100%" spacing={2} justifyContent="flex-end">
                  {actions}
                </Stack>
              </CardActions>
            </>
          )}
        </Card>
      </Main>
    </div>
  );
};

const PlanExpiredErrorPage = () => (
  <GenericErrorPage header={<FormattedMessage id="GenericError.planExpiredHeader" defaultMessage="Plan Expired" />}>
    <FormattedMessage
      id="GenericError.planExpiredMessage"
      defaultMessage="This promotion plan has expired and can no longer be accessed."
    />
  </GenericErrorPage>
);

export { GenericErrorPage, PlanExpiredErrorPage };
