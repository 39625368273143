import { defineMessage, useIntl, MessageDescriptor } from 'react-intl';
import { PrimitiveType, FormatXMLElementFn } from 'intl-messageformat';

type IntlAttributeOptions = {
  values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>;
} & MessageDescriptor;

/**
 * Helper to define internationalized text for html attributes
 *
 * react-intl docs suggest you have to use the imperative api with `intl` in order to set text
 * attributes on html, see https://formatjs.io/docs/react-intl/api/#why-imperative-api
 */
const useIntlAttribute = ({ id, defaultMessage, values }: IntlAttributeOptions) => {
  const intl = useIntl();
  const message = defineMessage({
    id,
    defaultMessage,
  });

  return intl.formatMessage(message, values);
};

export { useIntlAttribute };
