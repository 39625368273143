const getSiteCodeFromHostname = () => {
  // FIXME: move this product code and logo link URL to the API and just pass it in the app data payload and set it on context

  // Generally we prefer get the siteCode from data, such as a given meeting document
  // or group trip. But in cases where data is not available/applicable, we still need
  // to know which product to load content for. Hence this method.

  let siteCode: string = window.location.hostname;
  const hostname: string = window.location.hostname;

  const hostnamesForSiteCodeMap: { [value: string]: string[] } = {
    ETUS: ['promote.etus', 'eftours.com'],
    EAUS: ['promote.eaus', 'efexploreamerica.com'],
    ETCA: ['promote.etca', 'eftours.ca'],
    ETFC: ['promote.etfc', 'efvoyages.ca'],
  };

  for (const [code, hostnames] of Object.entries(hostnamesForSiteCodeMap)) {
    hostnames.forEach((validHostnameFragment: string) => {
      if (hostname.indexOf(validHostnameFragment) > -1) {
        siteCode = code;
      }
    });
  }
  return siteCode || 'ETUS';
};

const getLogoUrlFromSiteCode = (siteCode: string) => {
  switch (siteCode) {
    case 'EAUS':
      return 'https://www.efexploreamerica.com';
    case 'ETFC':
      return 'https://www.efvoyages.ca';
    case 'ETCA':
      return 'https://www.eftours.ca';
    case 'ETUS':
    default:
      return 'https://www.eftours.com';
  }
};

export { getSiteCodeFromHostname, getLogoUrlFromSiteCode };
