import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from './components/Error/ErrorBoundary';
import { logger } from './utils/logger';

type LazyPageComponentProps = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

/**
 * PageWithSuspense is a component to wrap a lazy loaded component with Suspense.
 * It exists to centralize our default fallback for loading JS chunks.
 */
const PageWithSuspense = ({ children, fallback = <></> }: LazyPageComponentProps) => {
  return <Suspense fallback={fallback}>{children}</Suspense>;
};

type PageProps = {
  name: string;
  children: React.ReactNode;
};

/**
 * createPageWrapper is a component factory to create a Page component to wrap children in
 * Suspense and additional page-render requirements, such as booking conditions. It is a
 * factory to remove the dependency on useRoutes within this module.
 *
 * @see routes.tsx for example usage
 */
const Page = ({ name, children }: PageProps) => {
  // Set a correlation id per page
  logger.setCorrelationId();
  logger.appendMeta({ route: name });

  // By using the location.key as our page component's key, react router knows when to reload the route if it's the same route but different param
  const location = useLocation();

  return (
    <React.Fragment key={location.key}>
      <ErrorBoundary>
        <PageWithSuspense>{children}</PageWithSuspense>
      </ErrorBoundary>
    </React.Fragment>
  );
};

export { Page };
