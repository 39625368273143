import { FormattedMessage } from 'react-intl';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { Breakpoint } from '@mui/system';
import Typography from '@mui/material/Typography'
import { useIntlAttribute } from '../../hooks/useIntlAttribute';

type AppFooterProps = {
  maxWidth: Breakpoint;
}

const AppFooter = ({ maxWidth }: AppFooterProps) => {
  const copyrightYear = new Date().getUTCFullYear().toString();
  return (
    <Container fixed maxWidth={maxWidth} sx={{ pt: 4, pb: 16 }} component='footer' role='contentinfo'>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='body2'>
          <FormattedMessage
            id='AppFooter.copyrightText'
            defaultMessage='© {copyrightYear} EF Education First International Ltd.'
            values={{ copyrightYear, br: <br /> }}
          />
        </Typography>
        <Typography variant='body2'>
          <a
            href={useIntlAttribute({
              id: 'AppFooter.legalNoticeUrl',
              defaultMessage: 'https://www.eftours.com/legal/legal-notices',
            })}
            target='_blank'
          >
            <FormattedMessage id='AppFooter.legalNoticeText' defaultMessage='Legal Notice' />
          </a>
        </Typography>
      </Stack>
    </Container>
  );
};

export { AppFooter };
