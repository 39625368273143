import { CustomerGlobalHeader } from '../header/CustomerGlobalHeader';
import { Layout } from '../layout/Layout';
import { GenericErrorPage } from './Errors';

function NotFound() {
  return (
    <Layout content={{}} globalHeader={<CustomerGlobalHeader />}>
      <GenericErrorPage header="Not found">Couldn't find the page you requested</GenericErrorPage>
    </Layout>
  );
}

export default NotFound; // eslint-disable-line import/no-default-export
