import axios, { AxiosResponse } from 'axios';
import { resolveUrlConfigTemplate } from '@local/cx-client-utils';

// FIXME: Replace this with logger-created axios instance
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: resolveUrlConfigTemplate(process.env.REACT_APP_BACKEND_URL),
});

// Always send the full referrer path to the api as a query param to be used when constructing
// returnTo or forwardTo urls when creating links that will eventually link back to the current page.
// With the way our app routing works, the referer header doesn't display the actual full href for the
// app request. It's not until react router starts making page-specific requests does the referer
// actually show the full href in the user's browser.
axiosInstance.interceptors.request.use((config) => {
  config.params = config.params || {};
  config.params['referrerPath'] = window.location.pathname;
  return config;
});

axiosInstance.interceptors.response.use(
  (resp) => resp,
  (error) => {
    const status = error?.response?.status;
    if (status == 401) {
      const resp = error.response as AxiosResponse;
      const redirectTo = resp?.data?.additionalData?.redirectTo;
      const returnRedirectParam = resp?.data?.additionalData?.redirectParam;

      // FIXME: consider additional URL/origin validation
      if (redirectTo && returnRedirectParam) {
        const authRedirect = new URL(redirectTo);
        authRedirect.searchParams.append(returnRedirectParam, window.location.href);
        window.location.replace(authRedirect);
      }
    }

    return Promise.reject(error);
  }
);

export { axiosInstance as axios };
