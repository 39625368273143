import { logger } from '../utils/logger';

const CLICK_EVENT = 'click';
const CLICK_EVENT_ID_ATTRIBUTE = 'data-event-id';
const IGNORE_EVENT_HINT_ATTRIBUTE = 'data-ignore-hint';
const MAX_TRANSVERSAL = 10;
const EVENT_HINT_MAX_LENGTH = 32;
const EVENT_DEBOUNCE = 1000;

/**
 * normalizeEventHint returns a string as a camel case value with a 32 character
 * limit.
 * See: https://stackoverflow.com/questions/4068573/convert-string-to-pascal-case-aka-uppercamelcase-in-javascript
 */
const normalizeEventHint = (val: string) => {
  return val
    .replace(/[-_]+/g, ' ')
    .replace(/[^\w\s]/g, '')
    .replace(
      /\s+(.)(\w*)/g,
      (_match, firstCharacter, remainingCharacters) =>
        `${firstCharacter.toUpperCase()}${remainingCharacters.toLowerCase()}`
    )
    .replace(/\w/, (s) => s.toLowerCase())
    .slice(0, EVENT_HINT_MAX_LENGTH);
};

/**
 * ClickEventData describes the shape of any click event log data
 */
type ClickEventData = {
  eventId: string;
  eventHint: string;
  eventType: string;
  url: string;
  [additionDataFields: string]: unknown;
};

/**
 * ElementEventDataGenerator is any function that takes an Element and returns ClickEventData
 */
type ElementEventDataGenerator = (target: Element) => ClickEventData;

/**
 * createEventDataGenerator returns ClickEventData based on a provided generator function and
 * default data required by ClickEventData.
 * @param generator A function that takes an element and returns additional event data.
 * @returns ElementEventDataGenerator
 */
const createEventDataGenerator =
  (generator: (target: Element) => Record<string, unknown>): ElementEventDataGenerator =>
  (target: Element) => {
    return {
      eventHint: target.getAttribute(IGNORE_EVENT_HINT_ATTRIBUTE) ? '' : normalizeEventHint(target.textContent || ''),
      ...generator(target),
      eventId: target.getAttribute(CLICK_EVENT_ID_ATTRIBUTE) || '',
      eventType: target.tagName.toLowerCase(),
      url: window.location.href,
    };
  };

const hashEventData = ({ eventId, eventHint, url }: ClickEventData) => `${eventId}::${eventHint}::${url}`;

/**
 * EventDataGenerators is a map of elements to generator functions for click events.
 */
const EventDataGenerators: Record<string, ElementEventDataGenerator> = {
  A: createEventDataGenerator((target: Element) => ({
    eventHint: target.getAttribute('href'),
    to: target.getAttribute('href'),
  })),
  BUTTON: createEventDataGenerator(() => ({})),
  INPUT: createEventDataGenerator((target: Element) => ({ eventHint: target.getAttribute('name') })),
};

const fallbackGenerator = createEventDataGenerator(() => ({}));

type ClickTrackingBoundaryProps = {
  children: React.ReactNode;
};

/**
 * ClickTrackingBoundary acts as a catch-all for all clicks within its children. It watches
 * for clicks, inspects them, and reports them to the logger if it's a tag it cares about.
 *
 * To provide an id to a click event, add `EVENT_NAME_ATTRIBUTE` to your React element.
 *
 * This component should exist only once in the component tree and wrap the clickable area
 * you care about.
 *
 * @see EventDataGenerators
 * @see EVENT_NAME_ATTRIBUTE
 * @see EVENT_DEBOUNCE
 */
const ClickTrackingBoundary = ({ children }: ClickTrackingBoundaryProps) => {
  // This isn't state because we don't want to maintain the value between function calls.
  const eventCache = new Set<string>();

  // handleClick deals with every click within this element, including children. Whenever an
  // event bubbles up, it checks to see if it's one that we care about and emits events when
  // required.
  const handleClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // This shouldn't ever be the case, but let's just check anyway. Events can be
    // wacky maybe.
    if (!event.target || !(event.target instanceof Element)) {
      return;
    }

    // Set our default iteration state.
    let target = event.target as HTMLElement | null;
    let iterN = 0;

    // While there are events and we haven't hit our max trans limit yet,
    // see if the current target is supported. This is limited to prevent
    // very deep dom trees killing app performance.
    while (target !== null && iterN <= MAX_TRANSVERSAL) {
      // If the current target has the data attr or a tag isn't one we care about
      // capture the event
      if (target.getAttribute(CLICK_EVENT_ID_ATTRIBUTE) !== null || EventDataGenerators[target.tagName]) {
        const eventData = (EventDataGenerators[target.tagName] || fallbackGenerator)(target);
        const eventHash = hashEventData(eventData);

        if (!eventCache.has(eventHash)) {
          logger.info(CLICK_EVENT, eventData);
          eventCache.add(eventHash);
          setTimeout(() => {
            if (eventCache) {
              eventCache.delete(eventHash);
            }
          }, EVENT_DEBOUNCE);
        }
      }

      // Immediately update the control flow to simplify data generation.
      // This sets the next target to the current's parent, and ups the iter
      // count.
      target = target.parentElement;
      iterN += 1;
    }
  };

  return (
    <div onClick={handleClick} onAuxClick={handleClick}>
      {children}
    </div>
  );
};

/**
 * eventId is a helper to add the correct data attributes to an element or component.
 * It is meant to be destructured in the element it's used in.
 *
 * @example
 * <a {...clickId('helpCenterLink')} href='/some/url' />
 */

const clickId = (id: string) => ({ [CLICK_EVENT_ID_ATTRIBUTE]: id });

/**
 * ignoreEventHint is a helper to hide text content information from being sent to our logs.
 * Use it with any components wrapping sensitive text (ie: customer names)
 * It is meant to be destructured in the element it's used in.
 *
 * @example
 * <a {...ignoreEventHint()} href='/some/url' />
 */
const ignoreEventHint = () => ({ [IGNORE_EVENT_HINT_ATTRIBUTE]: 'true' });

export { ClickTrackingBoundary, clickId, ignoreEventHint };
