import { Provider, useContext, createContext } from 'react';

/**
 * Little utility wrapper to make context, and only export the Context's Provider, and a hook to access that context
 * and ensure it's been initialized
 */
const createNonNullableContext = <T>(name?: string): [provider: Provider<T | null>, useContext: () => T] => {
  const Context = createContext<T | null>(null);
  const useNonNullableContext = () => {
    const ctx = useContext<T | null>(Context);
    if (!ctx) {
      throw `${name ? `${name} ` : ''}Provider was not initialized in this scope! You cannot use this hook here!`;
    }
    return ctx;
  };

  return [Context.Provider, useNonNullableContext];
};

export { createNonNullableContext };
