"use strict";
// FIXME: There may be an argument to move this into the web-config-module package. It's a little awkward because this only works
// in the client as its currently written.
exports.__esModule = true;
exports.resolveUrlConfigTemplate = void 0;
/**
 * currentEnv returns the current environment's parsed URL as a key/value
 * object. The return is the output of `new URL(window.location.href)`
 * with the exception of:
 *
 * rootHostname: root domain
 * productHostname: domain without the first subdomain.
 *
 * @returns Record<string, string>
 */
var currentEnv = function () {
    var currentURL = new URL(window.location.href);
    var domainParts = currentURL.hostname.split('.');
    var rootHostname = domainParts.slice(domainParts.length - 2).join('.');
    var productHostname = domainParts.length > 2 ? domainParts.slice(1).join('.') : domainParts.join('.');
    return {
        hash: currentURL.hash,
        hostname: currentURL.hostname,
        productHostname: productHostname,
        rootHostname: rootHostname,
        host: currentURL.host,
        pathname: currentURL.pathname,
        port: currentURL.port,
        protocol: currentURL.protocol,
        search: currentURL.search
    };
};
/**
 * resolve applies template variables to a given string value, usually a
 * configuration value. These variables can be used or ignored as part of the
 * string value provided. If an invalid variable is referenced, it will default to
 * an empty string. Values outside of interpolated variables are not mutated.
 *
 * @returns string
 * @see currentEnv
 * @example // For a hosted env of account.etus.example.com use a config url of
 * // https://backend.etus.example.com/
 * // In your config:
 * REACT_APP_BACKEND_URL=http://account.\${productHostname}:3002
 * // During app initialization:
 * const backendUrl = resolve(process.env.REACT_APP_BACKEND_URL) // returns https://account.etus.example.com:3002
 */
var resolveUrlConfigTemplate = function (config) {
    if (config === undefined) {
        return '';
    }
    var ctx = currentEnv();
    var resolved = config.replace(/\$\{([^}]+)\}/g, function (_substr, key) {
        return ctx[key] || '';
    });
    return resolved;
};
exports.resolveUrlConfigTemplate = resolveUrlConfigTemplate;
