import { FormattedMessage } from 'react-intl';
import { convert } from 'html-to-text';
import { useToast } from '../../components/Toast';

type SetClipboard = (text: string, type?: 'text/plain' | 'text/html') => void;

/**
 * Utility hook to create a ui toast for success/error states of copying text to clipboard
 */
const useCopyToClipboard = (): [SetClipboard, React.ReactNode] => {
  const [setSuccessToastOpen, successToast] = useToast({
    severity: 'success',
    autoHideDuration: 2000,
    message: <FormattedMessage id="CopyToClipboard.successMessage" defaultMessage="Copied to your clipboard!" />,
  });

  const [setErrorToastOpen, errorToast] = useToast({
    severity: 'error',
    autoHideDuration: 2000,
    message: <FormattedMessage id="CopyToClipboard.errorMessage" defaultMessage="Something went wrong." />,
  });

  type SetClipboardType = 'text/plain' | 'text/html';

  const setClipboard: SetClipboard = async (text: string, type: SetClipboardType = 'text/plain') => {
    const items: Record<string, Blob> = {};

    if (type === 'text/html') {
      items['text/plain'] = new Blob([convert(text, { wordwrap: false })], { type: 'text/plain' });
      items['text/html'] = new Blob([text], { type: 'text/html' });
    } else {
      items['text/plain'] = new Blob([text], { type: 'text/plain' });
    }

    try {
      const data = [new ClipboardItem(items)];
      await navigator.clipboard.write(data);
      setSuccessToastOpen(true);
    } catch {
      setErrorToastOpen(true);
    }
  };

  const notificationToasts = (
    <>
      {successToast}
      {errorToast}
    </>
  );

  return [setClipboard, notificationToasts];
};

export type { SetClipboard };
export { useCopyToClipboard };
